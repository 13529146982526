<template>
  <div class="item-box">
    <div class="title">{{ item.title }}</div>
    <div class="subtitle" v-for="(text, idx) in item.subList" :key="idx">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="less" scoped>
.item-box {
  position: relative;
  width: 1080px;
  margin: 0 auto;
  padding-bottom: 50px;
  &::before {
    position: absolute;
    left: -49px;
    top: 6px;
    z-index: 1;
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #0081ff;
  }
  &::after {
    position: absolute;
    left: -40px;
    top: 6px;
    content: "";
    width: 2px;
    height: 100%;
    background: #0081ff;
  }
}
.title,
.subtitle {
  line-height: 32px;
  font-size: 28px;
  color: #111;
}
.title {
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
}
.subtitle {
  font-size: 16px;
  color: #354f7f;
  margin-bottom: 10px;
}
</style>
