<template>
  <current-header selected="2"></current-header>
  <div class="space-fixed"></div>
  <update-item v-for="item in list" :key="item.id" :item="item"></update-item>
</template>

<script>
import currentHeader from "@/components/current-header/current-header";
import updateItem from "@/components/update/update-item";
import updateMixins from "./mixins";

export default {
  mixins: [updateMixins],
  components: {
    currentHeader,
    updateItem,
  },
};
</script>

<style lang="less" scoped>
.space-fixed {
  height: 88px;
  margin-bottom: 50px;
}
.item-box:nth-of-type(2) {
  &::before {
    border: 2px solid #0081ff;
    background: #fff;
    box-sizing: border-box;
  }
}
.item-box:last-of-type {
  &::after {
    height: 0;
  }
}
</style>
