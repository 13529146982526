const updateMixins = {
  data() {
    return {
      list: [
        {
          id: 11,
          title: "2021年7月22日",
          subList: [
            "·厉害了！全新的增长计划组合！",
            "·不会在粉丝睡觉的时间推送消息了 /来自反馈",
            "·真的上了抄自己的皮肤 /来自反馈",
            "·佣金的计算更符合逻辑了 /来自反馈",
            "·会员卡兑换失败之后可以更快的重新发放了",
            "·美团每周三大红包也出现在了吃饭提醒里",
            "·进一步增强了登录相关的安全性"
          ],
        },
        {
          id: 10,
          title: "2021年6月25日",
          subList: [
            "·鹅妹子嘤！粉丝增长功能开放了！",
            "·可以自定义会员签到的门槛了/来自反馈",
            "·对微信客服消息规则的调整做了应对",
            "·和平台一起提高了了结算的效率和质量 /来自反馈",
            "·修复了几个不严重的小BUG",
            "·还有一些七七八八的优化"
          ],
        },
        {
          id: 9,
          title: "2021年6月2日",
          subList: [
            "·上线了应对微信消息规则改版的策略-欢迎语",
            "·可以复制出自己小程序的路径了",
            "·在菜单中可以配置<a href>语法了",
            "·然后还修复了一些平台和小程序的BUG",
          ],
        },
        {
          id: 8,
          title: "2021年5月26日",
          subList: [
            "·从本周起可以为大家结算CPA的佣金了",
            "·粉丝的返利记录可以显示到120天",
            "·修复了一些平台和小程序的BUG",
          ],
        },
        {
          id: 7,
          title: "2021年5月19日",
          subList: [
            "·现在可以看到更多不同渠道数据了",
            "·增加了一个可爱一些的新皮肤",
            "·修复了关于授权关联、订单和会员卡的一些问题",
          ],
        },
        {
          id: 6,
          title: "2021年5月12日",
          subList: [
            "·满天星小程序也上线了轻内容的模块",
            "·可以创建属于自己的渠道推广二维码",
            "·可以导出订单明细了，数据管理更方便",
            "·提现时如果不小心选错发票可以重选了",
            "·上线了包含使用说明和答疑的使用手册",
            "·降低了消息推送错误的可能性",
            "·还修复了一些小BUG",
          ],
        },
        {
          id: 5,
          title: "2021年4月28日",
          subList: [
            "·提现的财务流程支持发票抵税了",
            "·增加了一个吃饭提醒的模板",
            "·更好的应对网络不稳定并修复了一些小bug",
          ],
        },
        {
          id: 4,
          title: "2021年4月21日",
          subList: [
            "·领红包送会员上线了",
            "·发布了可以无返利的互动模版",
            "·更新了吃饭提醒的互动性",
            "·解决了登录有效期和验证码的一些问题",
          ],
        },
        {
          id: 3,
          title: "2021年4月14日",
          subList: [
            "·提高了佣金提现和粉丝提现的安全性和效率",
            "·SaaS平台和小程序的运行更稳定",
            "·小程序首页红包动起来了、皮肤不在闪动了",
          ],
        },
        {
          id: 2,
          title: "2021年4月11日",
          subList: [
            "·支持自定义菜单配置了",
            "·新增随机红包（饿了么赏金红包）了",
            "·吃饭提醒的发送更加准时了",
          ],
        },
        {
          id: 1,
          title: "2021年4月9日",
          subList: [
            "·满天星正式上线，基于行业明星产品“外卖省很多”，通过完整、快速、高质量的封装赋能助力每个流量主高效进入市场，节省成本、提高效果、一步到位，为公众号建设优质业务能力。",
          ],
        },
      ],
    };
  },
};

export default updateMixins;
